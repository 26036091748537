.ant-input {
   &:disabled {
      color: #000000d9 !important;
   }
}

.ant-select-disabled {
   .ant-select-selector {
      color: #000000d9 !important;
   }
}

.ant-message-custom-content {
   display: flex;
   align-items: center;
}

.ant-menu {
   color: #000000a6 !important;
}

.ant-menu-item {
   margin-top: 0px !important;
}

.ant-dropdown-menu-submenu {
   .ant-dropdown-menu-submenu-title {
      color: #000000a6 !important;
   }
}

.login-button {
   width: 100%;
   @media (min-width: 1024px) {
      width: 50%;
   }
}

.general-icon-lang {
   width: 40px;
   height: 40px;

   svg {
      width: 40px;
      height: 40px;
   }
}

.general-lang-select {
   .ant-select-item-option-content {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .ant-select-selector {
      padding: 10px !important;
   }

   .ant-select-selection-item {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .ant-select-item {
      padding: 5px !important;
   }
}
