@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
   font-family: "Atlas Grotesk";
   src: url("./assets/fonts/Atlas Grotesk-Regular-VH.ttf");
}

@font-face {
   font-family: "Atlas Grotesk";
   src: url("./assets/fonts/Atlas Grotesk-Bold-VH.ttf");
   font-weight: 600;
}

@font-face {
   font-family: "Century BookCondensed";
   src: url("./assets/fonts/CenturyStd-LightCondensed-VH.ttf");
}

* {
   font-family: "Helvetica Neue", Helvetica, "HelveticaNeue-Light",
      "Helvetica Neue Light", Arial, "Lucida Grande", sans-serif;
}

html {
   font-size: 62.5%;
   scroll-behavior: smooth;
}

body {
   overflow-x: hidden;
   color: #000000a6 !important;
}

.icon-lang {
   width: 24px;
   height: 24px;
}

@keyframes fadeIn {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

.fade-in {
   animation: fadeIn 0.3s ease-in;
}
